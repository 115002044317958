var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "store-transfer"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "门店调货",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "cell-group-wrap"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.lists, function (list, index) {
    return _c('van-cell', {
      key: index,
      attrs: {
        "title": list.from_store.name + ' → ' + list.to_store.name,
        "value": '￥' + list.amount,
        "value-class": "amount-value"
      }
    });
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }