<template>
  <div class="store-transfer">
    <my-nav-bar
      title="门店调货"
      left-text="返回"
      left-arrow
    />
    <div class="cell-group-wrap">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell
        v-for="(list,index) in lists"
        :key="index"
        :title=" list.from_store.name + ' → ' + list.to_store.name"
        :value="'￥' + list.amount"
        value-class="amount-value"
      />
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getTotalTransData } from '@/api/statistics'
export default {
  components: { myNavBar },
  data() {
    return {
      lists: [],
      showEmpty: false
    }
  },
  created() {
    this.getLists()
  },
  methods: {
    getLists() {
      const params = {
        type: this.$route.query.type,
        date: this.$route.query.time,
        store_id: this.$route.query.store_id
      }
      this.beginLoad()
      getTotalTransData(params).then(res => {
        this.endLoad()
        this.lists = res.data
        this.showEmpty = this.lists.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.store-transfer{
  .cell-group-wrap{
    margin-top: 46px;
    padding: 0 15px;
    overflow: hidden;
    .van-cell{
      margin-top: 10px;
      .amount-value{
        color: red;
      }
    }
  }
}
</style>
